/*
 * @Author: 龙行锋 792575027@qq.com
 * @Date: 2023-11-18 20:11:40
 * @LastEditors: 龙行锋 792575027@qq.com
 * @LastEditTime: 2023-11-30 20:04:16
 * @FilePath: \DigitalTwin-y\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import axios from "axios";
import store from "@/store";
import qs from "qs";
import router from "@/router";

let loadingInstance;
const handleData = ({ data }) => {
  if (loadingInstance) loadingInstance.close();

  // return data;
  let { isSuccess, code, errorMsg } = data;
  let res = data;
  // return data;
  if (isSuccess) {
    return res;
  } else {
    window.alert("error");
  }
  return data;
};

export default (url) => {
  /**
   * @description axios初始化
   */

  //  requestTimeout
  const instance = axios.create({
    // baseURL: url || process.env.VUE_APP_URL,
    baseURL: 'http://1.94.25.19:9001',
    // baseURL: '',
    // baseURL: 'https://cloud.yhswszy.com:8012',
    timeout: 20000,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
  /**
   * @description axios请求拦截器
   */
  instance.interceptors.request.use(
    (config) => {
      let i18n = store.state["language"] || "zh";
      config.headers["Accept-Language"] = i18n;
      if (store.state["accessToken"])
        config.headers["Authorization"] = store.state["accessToken"];

      if (store.state["userinfo"]) {
        config.headers["cnt-current-user-code"] =
          store.state["userinfo"].userCode;
        config.headers["cnt-current-top-unit"] =
          store.state["userinfo"].topUnit;
      }
      // config.headers['cnt-current-user-code'] = 'u0000000'
      // config.headers['cnt-current-top-unit'] = '1'
      if (
        config.data &&
        config.headers["Content-Type"] ===
          "application/x-www-form-urlencoded;charset=UTF-8"
      )
        config.data = qs.stringify(config.data);
      // if ([''].some((item) => config.url.includes(item)))
      //   loadingInstance = Vue.prototype.$baseLoading()
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /**
   * @description axios响应拦截器
   */
  instance.interceptors.response.use(
    (response) => handleData(response),
    (error) => {
      if (loadingInstance) loadingInstance.close();
      const { response } = error;
      if (response === undefined) {
        // Vue.prototype.$Message(
        //   '未可知错误，可能是由于后端不支持跨域CORS或代理配置无效引起',
        //   'error'
        // )
        // window.alert(
        //   "未可知错误，可能是由于后端不支持跨域CORS或代理配置无效引起"
        // );
        return {};
      } else return handleData(response);
    }
  );

  return instance;
};
