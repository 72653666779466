<template>
  <div id="home">
    <Container class="bg" ref="container" :style="homeStyle">
      <video
        ref="video"
        preload="metadata"
        crossorigin="anonymous"
        src="@/assets/yc1.webm"
        autoplay="autoplay"
        loop="loop"
        muted="muted"
        width="100%"
        height="100%"
        style="width= 100%; height=100%; object-fit: fill;position: absolute;"
      ></video>
      <iframe
        ref="myFrame"
        class="iframe myFrame"
        width="100%"
        allowtransparency="true"
        style="position: absolute;right:5px;border-width: 0;background-color=transparent;width:325px;height:32px;z-index: 9999;"
        height="100%"
        title="myFrame"
        src="https://i.tianqi.com?c=code&id=34&icon=1&site=18&color=%23FFFFFF"
      ></iframe>
      <div class="header">
        <span class="title">瓶窑观测场</span>
      </div>
      <div class="left">
        <div class="each-chart">
          <div class="childHeader">
            <span class="childtitle">雨量</span>
          </div>
          <div id="mychart1" style="width: 100%; height: calc(100% - 100px)" />
        </div>
        <div class="each-chart">
          <div class="childHeader">
            <span class="childtitle">瓶窑水位</span>
          </div>
          <div id="mychart2" style="width: 100%; height: calc(100% - 100px)" />
        </div>
        <div class="each-chart">
          <div class="childHeader">
            <span class="childtitle">流量</span>
          </div>
          <div id="mychart3" style="width: 100%; height: calc(100% - 100px)" />
        </div>
      </div>
      <div class="right">
        <div class="each-chart">
          <div class="childHeader">
            <span class="childtitle">蒸发</span>
          </div>
          <div id="mychart4" style="width: 100%; height: calc(100% - 100px)" />
        </div>
        <div class="each-chart">
          <div class="childHeader">
            <span class="childtitle">地下水</span>
          </div>
          <div id="mychart5" style="width: 100%; height: calc(100% - 100px)" />
        </div>
        <div class="each-chart">
          <div class="childHeader">
            <span class="childtitle">土壤墒情</span>
          </div>
          <div id="mychart6" style="width: 100%; height: calc(100% - 100px)" />
        </div>
      </div>
      <div class="wrape" :style="wrapeStyle">
        <Model ref="viewer">
          <Msymbol :modelList="symbolList" @progress="progress" />
          <Css2D ref="css2d" />
          <Css3D ref="css3d" />
          <!-- 模型控制 -->
          <ModelPanel ref="modelPanel" />
          <!-- <Effect /> -->
        </Model>
        <Layers ref="layer" />
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "./components/container";
import ModelPanel from "./layers/panel/modelPanel.vue";
import Model from "./model";
import Layers from "./layers";
import Msymbol from "./model/symbol";
import Css2D from "./model/css2D";
import Css3D from "./model/css3D";
import Effect from "./model/effect";
import { coordinate, getChartData } from "./api/monitorLine";
import { formatTime } from "@/utils";
import axios from "axios";
import { autoHover } from "@/utils/echarts_auto_tooltip.js";
export default {
  data() {
    return {
      symbolList: [],
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      chartObj: [],
      clearLoop: null,
    };
  },
  components: {
    Container,
    Model,
    Effect,
    Msymbol,
    Css2D,
    Css3D,
    Layers,
    ModelPanel,
  },
  computed: {
    wrapeStyle() {
      const { width, height } = this.$store.state.screen;
      return {
        width: width + "px",
        height: height + "px",
      };
    },
    homeStyle() {
      const {
        grayscale,
        brightness,
        contrast,
        saturate,
        opacity,
        sepia,
      } = this.$store.state.layer;
      return {
        background: this.$store.state.screen.backgroundColor,
        filter: `grayscale(${grayscale}%) brightness(${brightness}%) contrast(${contrast}%) saturate(${saturate}%) opacity(${opacity}%) sepia(${sepia}%)`,
      };
    },
  },
  watch: {
    "$store.state.model.curSelectModel"(mesh) {
      console.log(mesh);
    },
  },
  mounted() {
    const that = this;
    this.getSymbolList();
    setTimeout(() => {
      this.myChart1 = this.$echarts.init(document.getElementById("mychart1"));
      this.myChart2 = this.$echarts.init(document.getElementById("mychart2"));
      this.myChart3 = this.$echarts.init(document.getElementById("mychart3"));
      this.myChart4 = this.$echarts.init(document.getElementById("mychart4"));
      this.myChart5 = this.$echarts.init(document.getElementById("mychart5"));
      this.myChart6 = this.$echarts.init(document.getElementById("mychart6"));
      this.getData();
    }, 3000);
    setInterval(() => {
      this.getData();
    }, 1000*60*5);
  },
  methods: {
    getSymbolList(id) {
      this.symbolList = [
        {
          name: "processed",
          url: "/model/new.glb" ,
          // url: "http://pinyaomoxing.oss-cn-hangzhou.aliyuncs.com/moxing/new.glb",
          draco: true, // 是都DICOM文件，是则需要 DRACOLoader来加载
          onprogress: true,
          callback: (group) => {
            // group.position.z = -1;
            // group.position.x = 3;
            group.scale.set(1, 1, 1);
            group.visible = true;
            // coordinate().then((res) => {
            //   if (res.success) {
            //     let list = this.$refs.css2d.lineList;
            //     list.map((item) => {
            //       let order = item.target && item.target.split("#").pop();
            //       res.data.forEach((val, index) => {
            //         if (val.lineOrder && order == val.lineOrder) {
            //           // val.order
            //           item.params = val;
            //           item.visible = true;
            //         } else if (order == index) {
            //           item.params = val;
            //           item.visible = true;
            //         }
            //       });
            //     });
            //   }
            // });
          },
        },
        // {
        //   name: "line",
        //   url: "http://qiniu.wantonly-drag.com.cn/model/kuangchang/20.gltf",
        //   draco: false,
        //   callback: (group) => {
        //     // group.position.y = -2;
        //     group.scale.set(0.001, 0.001, 0.001);
        //     group.visible = false;
        //   },
        // },
        // {
        //   name: "9",
        //   url: "model/9.gltf",
        //   draco: false,
        //   onprogress: true,
        //   callback: (group) => {
        //     group.position.set(0, 0, 0.5);
        //     group.scale.set(0.001, 0.001, 0.001);
        //   },
        // },
      ];
    },
    getData() {
      const that = this;
      let start = new Date(); // 获取当前日期和时间
      start.setHours(8, 0, 0, 0); // 设置时间为早上8点

      let end = new Date(); // 获取明天的日期和时间
      end.setHours(8, 0, 0, 0); // 设置时间为早上8点
      end.setDate(end.getDate() + 1); // 增加一天
      // const data = {
      //   beginTime: formatTime(start),
      //   endTime: formatTime(end),
      // };
      const data = {
        url:
          "https://lyslj.hzyuhang.cn:8012/datacenter/realTimeData/queryByTime/yhsw_station",
        method: "post",
        body: {
          beginTime: formatTime(start),
          endTime: formatTime(end),
        },
      };

      getChartData(data)
        .then(function(response) {
          // console.log(response,999);
          that.chartObj = response.data;
          that.setCharts();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    progress(percent) {
      this.$refs.viewer.percent = percent;
    },
    setCharts() {
      const option = {
        title: {
          text: "",
        },
        grid: {
          top: "10%",
          left: "10%",
          right: "10%",
          bottom: "20%",
        },
        legend: {
          data: [],
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0, 0, 0, 0.3)", // 设置tooltip背景色为半透明黑色
          textStyle: {
            color: "#FFF",
            fontFamily: "Arial",
            fontSize: 12,
          },
          borderWidth: "1",
          borderColor: "gray",
          axisPointer: {
            type: "cross",
            lineStyle: {
              color: "#fff",
            },
            label: {
              color: "#F00",
            },
          },
        },
        dataZoom: [
          {
            show: false,
            type: "slider",
            xAxisIndex: 0,
            start: 0,
            end: 100,
            backgroundColor: "rgba(47,69,84,0)",
            fillerColor: "inherit",
            color: "inherit",
          },
        ],
        // legend: {
        //   data: ["销量"],
        // },
        xAxis: {
          data: [],
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(52, 174, 111, 1)",
            },
          },
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: "rgba(14, 164, 210, 1)",
              fontSize: 10,
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
            lineStyle: {
              type: "dashed",
              color: "rgba(52, 174, 111, 1)",
            },
            interval: "auto",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(14, 164, 210, 1)",
            },
          },
        },
        series: [
          {
            name: "雨量",
            type: "line",
            data: [],
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgb(47,198,197,0.881043478861893)",
                  },
                  {
                    offset: 1,
                    color: "rgb(194,217,243,0.7914880364100173)",
                  },
                ],
              },
              barBorderRadius: [0, 0, 0, 0],
            },
          },
        ],
      };
      let x1 = []; // 雨量
      let y1 = []; // 雨量
      let x2 = []; // 水位
      let y2 = []; // 水位
      let x3 = []; // 流量
      let y3 = []; // 流量
      let x4 = []; // 蒸发
      let y4 = []; // 蒸发
      let x5 = []; // 地下水
      let y5 = []; // 地下水
      let x6 = []; // M10
      let y6 = []; // M10
      let x7 = []; // M20
      let y7 = []; // M20
      let x8 = []; // M30
      let y8 = []; // M30
      let x9 = []; // M40
      let y9 = []; // M40
      let x10 = []; // M50
      let y10 = []; // M50
      for (let index = 0; index < this.chartObj.length; index++) {
        const element = this.chartObj[index];
        if (element.item.includes("P") || element.item.includes("p")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x1.push(t);
          y1.push(Number(element.value).toFixed(2));
        }
        if ((element.item.includes("Z") || element.item.includes("z")) && element.stcd == "63001200") {
          const t = element.time.split(" ")[1].substring(0, 5);
          x2.push(t);
          y2.push(Number(element.value).toFixed(2));
        }

        if (element.item.includes("Q") || element.item.includes("q")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x3.push(t);
          y3.push(Number(element.value).toFixed(2));
        }

        if (element.item.includes("E") || element.item.includes("e")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x4.push(t);
          y4.push(Number(element.value).toFixed(2));
        }

        // "stcd": "30032380"为地下水

        if (element.stcd == "30032380") {
          const t = element.time.split(" ")[1].substring(0, 5);
          x5.push(t);
          y5.push(Number(element.value).toFixed(2));
        }

        if (element.item.includes("M10")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x6.push(t);
          y6.push(Number(element.value).toFixed(2));
        }
        if (element.item.includes("M20")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x7.push(t);
          y7.push(Number(element.value).toFixed(2));
        }
        if (element.item.includes("M30")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x8.push(t);
          y8.push(Number(element.value).toFixed(2));
        }
        if (element.item.includes("M40")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x9.push(t);
          y9.push(Number(element.value).toFixed(2));
        }
        if (element.item.includes("M50")) {
          const t = element.time.split(" ")[1].substring(0, 5);
          x10.push(t);
          y10.push(Number(element.value).toFixed(2));
        }
      }

      const option1 = JSON.parse(JSON.stringify(option));
      option1.xAxis.data = x1;
      option1.series[0].data = y1;
      option1.series[0].name = "雨量";

      const option2 = JSON.parse(JSON.stringify(option));
      option2.xAxis.data = x2;
      option2.series[0].data = y2;
      option2.series[0].type = "line";
      option2.series[0].name = "瓶窑水位";

      const option3 = JSON.parse(JSON.stringify(option));
      option3.xAxis.data = x3;
      option3.series[0].data = y3;
      option3.series[0].name = "流量";

      const option4 = JSON.parse(JSON.stringify(option));
      option4.xAxis.data = x4;
      option4.series[0].data = y4;
      option4.series[0].name = "蒸发";

      const option5 = JSON.parse(JSON.stringify(option));
      option5.xAxis.data = x5;
      option5.series[0].data = y5;
      option5.series[0].name = "地下水";

      const option6 = JSON.parse(JSON.stringify(option));
      option6.xAxis.data = x6;
      option6.series[0].data = y6;
      option6.series[0].name = "M10";
      option6.series[0].type = "line";
      // option6.legend = {
      //   show: true,
      //   itemStyle: {
      //     color: "inherit",
      //   },
      //   orient: "horizontal",
      //   left: "center",
      //   top: "top",
      //   data: ["M10", "M20", "M30", "M40", "M50"],
      //   textStyle: {
      //     color: "inherit",
      //     fontSize: 10,
      //     fontWeight: 500,
      //   },
      // };
      option6.series[1] = {
        name: "M20",
        type: "line",
        data: y7,
      };
      option6.series[2] = {
        name: "M30",
        type: "line",
        data: y8,
      };
      option6.series[3] = {
        name: "M40",
        type: "line",
        data: y9,
      };
      option6.series[4] = {
        name: "M50",
        type: "line",
        data: y10,
      };

      this.myChart1.setOption(option1, true); // 雨量
      this.myChart2.setOption(option2, true); // 水位
      this.myChart3.setOption(option3, true); // 雨量
      this.myChart4.setOption(option4, true); // 蒸发
      this.myChart5.setOption(option5, true); // 地下水
      this.myChart6.setOption(option6, true); // 土壤墒情
      // 定时轮播
      autoHover(this.myChart1, option1, 100, 3000); // 参数分别为：容器，配置，轮播数量，轮播间隔时间
      autoHover(this.myChart2, option2, 100, 3000); // 参数分别为：容器，配置，轮播数量，轮播间隔时间
      autoHover(this.myChart3, option3, 100, 3000); // 参数分别为：容器，配置，轮播数量，轮播间隔时间
      autoHover(this.myChart4, option4, 100, 3000); // 参数分别为：容器，配置，轮播数量，轮播间隔时间
      autoHover(this.myChart5, option5, 100, 3000); // 参数分别为：容器，配置，轮播数量，轮播间隔时间
      autoHover(this.myChart6, option6, 100, 3000); // 参数分别为：容器，配置，轮播数量，轮播间隔时间
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
#home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("../assets/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .bg {
    transition: 1s all;
    // background: #000;
    background: url("../assets/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .header {
      position: absolute;
      height: 100px;
      width: 100%;
      background: url("../assets/img/header3.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 9999;

      .title {
        display: flex;
        justify-content: center;
        // align-items: center;
        margin-top: 30px;
        color: #fff;
        font-size: 30px;
        font-weight: 700;
      }
    }
    .childHeader {
      // position: absolute;
      height: 50px;
      width: 100%;
      background: url("../assets/img/16974409946169399.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 9999;
      .childtitle {
        display: flex;
        // justify-content: center;
        // align-items: center;
        margin: 15px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .left {
      position: absolute;
      width: 20%;
      top: 100px;
      left: 0;
      height: calc(100% - 100px);
      // background: red;
      z-index: 9999;
      display: flex;
      flex-direction: column;
    }
    .right {
      position: absolute;
      width: 20%;
      top: 100px;
      right: 0;
      height: calc(100% - 100px);
      // background: red;
      z-index: 9999;
    }
    .each-chart {
      height: calc((100% - 100px) / 3);
      // background: red;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }
  .wrape {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .button {
      position: absolute;
      top: 50%;
      right: 350px;
      z-index: 10;
      color: #fff;
    }
  }
}
</style>
